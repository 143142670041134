export function findFromImages(name,data,find=true,type='fluid'){
    let found
    if(find){
       found = data.allFile.edges.find( 
        el => el.node.name.includes(name) 
      )   
    }else {
       found = data.allFile.edges.filter( 
        el => el.node.name.includes(name)
      )   
    }
    let arr = []
    if(found?.length > 0 ){
      found.forEach( element =>   arr.push(element.node.childImageSharp[type]) );
      found = [...arr]
    } else {
      found = found.node.childImageSharp.fluid
    }
  
    return found
}


export function next(index,setIndex,elements){
  let i = index + 1
  if(i >= elements.length){
    setIndex(0)
      return
  }
  setIndex(i)
}

export function prev(index,setIndex,elements){
  let i = index - 1
  if(i < 0){
      setIndex(elements.length-1)
      return
  }
  setIndex(i)
}
